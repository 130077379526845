import title from 'components/seo/title';
import { NextPage } from 'next';
import Head from 'next/head';
import NextLink from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';

const NotFound: NextPage = () => {
  const intl = useIntl();

  return (<>
    <Head>
      {title(
        intl.formatMessage({
          id: 'error:page:page-title:page-not-found',
        })
      )}
    </Head>
    <div
      className="container mb-10 flex flex-col items-center justify-center py-10"
      style={{ minHeight: '40vh' }}
    >
      <div className="flex flex-col">
        <NextLink href="/" aria-label="Pets Deli" className="mb-4 mr-auto lg:mr-0">

          <img
            src="/images/petsdeli-logo.svg"
            className="w-auto"
            alt="Pets Deli"
            role="presentation"
          />

        </NextLink>

        <h1 className="text-3xl font-black text-primary-main ">
          <FormattedMessage id="error:page:header" />
        </h1>
        <p className="py-4 text-primary-main">
          <FormattedMessage id="error:page:suggestions:header" />
        </p>
        <NextLink href="/" className="mb-3 text-brand-blue-neu underline">

          <FormattedMessage id="error:page:suggestions:home" />

        </NextLink>
        <NextLink
          href={`/pages/${intl.formatMessage({
            id: 'error:page:suggestions:foodaid:handle',
          })}`}
          className="mb-3 text-brand-blue-neu underline">

          <FormattedMessage id="error:page:suggestions:foodaid" />

        </NextLink>
        <NextLink href="/account" className="mb-3 text-brand-blue-neu underline">

          <FormattedMessage id="error:page:suggestions:account" />

        </NextLink>

        <NextLink href="/cart" className="mb-3 text-brand-blue-neu underline">

          <FormattedMessage id="error:page:suggestions:cart" />

        </NextLink>
      </div>
    </div>
  </>);
};

export default NotFound;
