import { useConfig } from 'config';

const Canonical = (url: string | null | undefined, omitSlash = false) => {
  const { frontendUrl } = useConfig();
  let pageUrl: string | undefined;

  if (omitSlash) {
    pageUrl = `${frontendUrl}${url}`;
  } else {
    pageUrl = `${frontendUrl}/${url}`;
  }
  return (
    <>
      <link rel="canonical" href={pageUrl} />
      <meta name="og:url" property="og:url" content={pageUrl} />
    </>
  );
};

export default Canonical;
