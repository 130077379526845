import { IProductPages } from 'global';
import { ContentfulProvider } from 'modules/content-cms/components/context';
import { GetStaticProps, NextPage } from 'next';
import getShopId from 'utils/i18n/get-shop-id';
import {
  FetchContentRes,
  fetchContentfulByHandle,
  fetchContentfulShopifyData,
} from '../connectors/contentful';
import Landingpage from '../modules/landingpage/home';
import withFallback from '../utils/with-fallback';

const Home: NextPage<{
  contentfulData: IProductPages;
  contentfulHandle: string;
  contentfulShopifyData: FetchContentRes;
}> = ({ contentfulData, contentfulHandle, contentfulShopifyData }) => (
  <ContentfulProvider
    handle={contentfulHandle}
    shopifyData={contentfulShopifyData}
    fallbackData={contentfulData}
  >
    <Landingpage />
  </ContentfulProvider>
);

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const shopId = getShopId(locale);
  const contentfulHandle = `home`;

  try {
    const contentfulData = await fetchContentfulByHandle({
      handle: contentfulHandle,
      locale,
    });

    if (contentfulData === null) {
      return { notFound: true, revalidate: true };
    }

    return {
      props: {
        contentfulData,
        contentfulHandle,
        contentfulShopifyData: await fetchContentfulShopifyData(
          contentfulData,
          shopId
        ),
      },
      revalidate: 600, // 10 minutes
    };
  } catch (error) {
    console.error(`Error while prefetching data for page: ${contentfulHandle}`);
    return { notFound: true, revalidate: true };
  }
};

export default withFallback(Home);
