import Button from 'components/button-new';
import ImpressionTrackerNew from 'components/impression-tracker/new-impression-tracker';
import canonical from 'components/seo/canonical';
import description from 'components/seo/description';
import image from 'components/seo/image';
import robots from 'components/seo/robots';
import title from 'components/seo/title';
import seotype from 'components/seo/type';
import useRefresh from 'hooks/common/use-refresh';
import { useContentful } from 'modules/content-cms/components/context';
import Head from 'next/head';
import Image from "next/legacy/image";
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { jsonLdScriptProps } from 'react-schemaorg';
import { Organization } from 'schema-dts';
import { gtm, selectElement } from 'tracking';
import InfluencerCollectionSlider from '../components/influencer-colletion-slider';
import bowlImage from './bowl.png';

const TRACKING_ELEMENT = 'Pet Type Selector';

const Landingpage: React.FC = () => {
  const { data } = useContentful();
  useRefresh();

  return (
    <>
      <Head>
        {title(data.fields.headline)}
        {description(data.fields.metaDescription)}
        {seotype()}
        {robots(data.fields.metaRobots)}
        {canonical('')}
        {image(
          data.fields.metaOgImage && data.fields.metaOgImage.fields.file.url
        )}
        <script
          {...jsonLdScriptProps<Organization>({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            '@id': '#organization',
            sameAs: [
              'https://www.facebook.com/petsdeli',
              'https://www.instagram.com/petsdeli',
              'https://www.pinterest.de/petsdeli',
              'https://www.linkedin.com/company/pets-deli',
            ],
            name: 'Pets Deli',
            url: 'https://www.petsdeli.de',
            legalName: 'Pets Deli Tonius GmbH',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Winsstr. 62',
              addressLocality: 'Berlin',
              addressRegion: 'DE-BE',
              addressCountry: 'GER',
              postalCode: '10405',
            },
            logo: {
              '@type': 'ImageObject',
              caption: 'Pets Deli',
              url: 'https://www.petsdeli.de/images/petsdeli-logo.svg',
            },
            foundingDate: '2017',
            contactPoint: {
              '@type': 'ContactPoint',
              telephone: '+4930220564405',
              email: 'office@petsdeli.de',
              contactType: 'Customer Support',
            },
          })}
          data-test="organization-schema"
        />
      </Head>

      <InfluencerCollectionSlider
        className="lg:mb-16"
        data-test="influencer-collection-slider"
      />
      <div
        className="flex min-h-[calc(100svh_-_var(--header-height))] flex-col bg-primary-main px-4 text-center text-other-white lg:px-0"
        data-test="pet-type-container"
      >
        <h1
          className="mx-auto mb-2 mt-12 max-w-[568px] whitespace-pre-wrap mindset-48 lg:mt-16 lg:mindset-80"
          data-test="landingpage-title"
        >
          <FormattedMessage id="landingpage:home:home:title" />
        </h1>

        <p
          className="mb-6 text-sm leading-5 lg:mb-8 lg:text-xl lg:leading-7"
          data-test="landingpage-sub-title"
        >
          <FormattedMessage
            id="landingpage:home:home:sub-title"
            values={{
              span: (text) => <span className="font-medium" data-test="landingpage-sub-title-span">{text}</span>,
            }}
          />
        </p>

        <ImpressionTrackerNew element={TRACKING_ELEMENT}>
          <div className="mb-14 flex justify-center gap-3 lg:mb-20" data-test="pet-type-buttons-container">
            <Button
              className="max-w-[272px]"
              href="/pages/hunde"
              onClick={() => {
                gtm(
                  selectElement({
                    element: ['Pet Type Selector', 'dog'],
                  })
                );
              }}
              layout={'contained'}
              size="large"
              data-test="button-for-dogs"
            >
              <FormattedMessage id="common:for-dogs" />
            </Button>

            <Button
              className="max-w-[272px]"
              href="/pages/katzen"
              onClick={() => {
                gtm(
                  selectElement({
                    element: ['Pet Type Selector', 'cat'],
                  })
                );
              }}
              layout={'contained'}
              size="large"
              data-test="button-for-cats"
            >
              <FormattedMessage id="common:for-cats" />
            </Button>
          </div>
        </ImpressionTrackerNew>

        <div className="mx-auto mt-auto w-full max-w-2xl" data-test="bowl-image-container">
          <Image
            src={bowlImage}
            objectFit="cover"
            layout="responsive"
            quality={100}
            data-test="bowl-image"
          />
        </div>
      </div>
    </>
  );
};

export default Landingpage;