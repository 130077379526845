import config from 'config';

const Robots = (
  robots?:
    | 'index, follow'
    | 'noindex, follow'
    | 'index, nofollow'
    | 'noindex, nofollow'
    | ''
    | undefined
    | null
): JSX.Element => {
  // Set meta tag if this build is used for production traffic
  const isLive = config.isLive === 'true';

  return (
    <meta
      name="robots"
      content={isLive ? robots || 'index, follow' : 'noindex, nofollow'}
      data-test="robots-meta"
    />
  );
};

export default Robots;
